.root {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}


.cards {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
}

.items {
  width: 50%;
  @media (max-width: 796px) {
    width: 100%;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;

  .title__text {
    color: #5fb1a2;
    font-size: 60px;
    text-transform: uppercase;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    padding-left: 70px;

    @media (max-width: 790px) {
      font-size: 50px;
      padding-left: 60px;
    }
    @media (max-width: 576px) {
      font-size: 40px;
      padding-left: 40px;
    }

    @media (max-width: 480px) {
      font-size: 30px;
      padding-left: 35px;
    }

    @media (max-width: 380px) {
      font-size: 25px;
      padding-left: 30px;
    }
  }

  .title__button {
    background: #5fb1a2;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    padding: 5px 25px;
    margin-right: 30px;
    cursor: pointer;

    @media (max-width: 380px) {
      margin-right: 10px;
    }

    .button__link {
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-size: 20px;

      @media (max-width: 480px) {
        font-size: 15px;
      }
    }
  }

  .title__button:hover {
    background: #fec24a;
  }
}
