%button {
  background: #fec24a;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  border: none;
}

.readBooks {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-around;
}

.books__allBooks {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;

  img {
    width: 180px;
  }
}

.book {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    @extend %button;
    font-size: 17px;
    padding: 5px 30px;
  }
}

.ad {
  min-width: 250px;
  height: 370px;
  background: burlywood;
  margin-left: 30px;
}

.books__title {
  display: flex;
  justify-content: space-around;

  .title__text {
    color: #5fb1a2;
    text-transform: uppercase;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 70px;
    margin-left: -20px;
  }
}

.title__buttonMore {
  @extend %button;
  font-size: 20px;
  padding: 10px 30px;
  cursor: pointer;
  text-decoration: none;
  color: white;

  &:hover {
    background: #5fb1a2;
    color: white;
  }
  &:visited {
    color: white;
  }
}

@media (max-width: 1200px) {
  .ad {
    margin-left: 0;
  }

  .books__allBooks {
    img {
      width: 160px;
    }
  }

  .books__title {
    .title__text {
      font-size: 60px;
    }
  }
}

@media (max-width: 992px) {
  .readBooks {
    align-items: center;
  }

  .books__allBooks {
    flex-wrap: wrap;

    img {
      padding-top: 20px;
      width: 230px;
    }
  }
  .book {
    width: 50%;
  }

  .books__title {
    width: 100%;
    align-items: center;
    .title__text {
      margin-left: 50px;
    }
  }
}

@media (max-width: 780px) {
  .readBooks {
    flex-direction: column;
  }
  .ad {
    margin-top: 30px;
  }

  .books__title {
    .title__text {
      font-size: 50px;
    }
  }
}

@media (max-width: 650px) {
  .books__title {
    .title__text {
      font-size: 35px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 500px) {
  .books__allBooks {
    img {
      width: 180px;
    }
  }

  .books__title {
    .title__text {
      font-size: 30px;
      align-items: center;
    }
  }
}

@media (max-width: 350px) {
  .book {
    width: 100%;
  }
}
