.header {
    background: #5fb1a2;
    padding: 15px 15px;

}


.head__main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;

}

.head__main__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin-top: 140px;
}



.head__main__info__text {
    max-width: 600px;
    p {
        color: #f2dfc0;
        text-transform: uppercase;
        font-size: 80px;
        text-align: center;
        line-height: 1;
        font-weight: 300;
        font-family: Impact, 'Oswald', sans-serif;
        font-stretch: condensed;
    }
}


.head__main__info__circles {
    img {
        width: 150px;
        margin-top: 60px;
    }
}

.head__main__photo {
    img {
        width: 600px;
    }
}


@media (max-width: 1220px) {
    .head__main__info {
        margin-top: 100px;
    }

    .head__main__info__text {
        max-width: 550px;
        p {
            font-size: 65px;
        }
    }

    .head__main__photo {
        img {
            width: 550px;
        }
    }
}

@media (max-width: 1100px) {
    .head__main__info {
        margin-top: 80px;
    }

    .head__main__info__text {
        max-width: 500px;
        p {
            font-size: 60px;
        }
    }

    .head__main__info__circles {
        img {
            width: 120px;
            margin-top: 50px;
        }
    }

    .head__main__photo {
        img {
            width: 500px;
        }
    }
}

@media (max-width: 992px) {
    .head__main__info {
        margin-top: 70px;
    }

    .head__main__info__text {
        max-width: 450px;
        p {
            font-size: 55px;
        }
    }

    .head__main__info__circles {
        img {
            width: 100px;
            margin-top: 40px;
        }
    }

    .head__main__photo {
        img {
            width: 450px;
        }
    }
}

@media (max-width: 890px) {
    .head__main__info {
        margin-top: 60px;
    }

    .head__main__info__text {
        max-width: 400px;
        p {
            font-size: 50px;
        }
    }

    .head__main__info__circles {
        img {
            width: 80px;
            margin-top: 30px;
        }
    }

    .head__main__photo {
        img {
            width: 400px;
        }
    }
}

@media (max-width: 790px) {
    .head__main {
        flex-direction: column-reverse;
    }
    .head__main__photo {
        margin-top: -40px;
        img {
            width: 500px;
        }
    }

    .head__main__info {
        margin-top: 20px;
    }

    .head__main__info__circles {
        img {
            width: 100px;
        }
    }
}

@media (max-width: 650px) {

    .head__main__photo {
        margin-top: 0px;
        img {
            width: 500px;
        }
    }
}

@media (max-width: 576px) {

    .head__main__photo {
        img {
            width: 450px;
        }
    }
}

@media (max-width: 450px) {

    .head__main__photo {
        img {
            width: 400px;
        }
    }

    .head__main__info__text {
        p {
            font-size: 45px;
        }
    }


    .head__main__info__circles {
        img {
            width: 80px;
        }
    }
}

@media (max-width: 400px) {

    .head__main__photo {
        img {
            width: 300px;
        }
    }

    .head__main__info__text {
        p {
            font-size: 40px;
        }
    }


    .head__main__info__circles {
        img {
            width: 60px;
        }
    }
}

