@mixin style {
  color: white;
  text-transform: uppercase;
}

.root {
  margin-top: 50px;
  width: 100%;
  background: #5fb1a2;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
  flex-basis: 38%;

  @media (max-width: 992px) {
    font-size: 14px;
  }

  @media (max-width: 870px) {
    font-size: 12px;
  }

  @media (max-width: 755px) {
    font-size: 11px;
  }

  @media (max-width: 420px) {
    font-size: 10px;
  }
}

.moreInformation {
  display: flex;
  flex-direction: column;
  @include style;
}

.moreInformation__items {
  text-decoration: none;
  color: white;
}

.name {
  font-family: Impact, 'Oswald', sans-serif;
  text-align: center;
  @include style;
  font-size: 35px;
  color: #fde4c3;
  flex-basis: 28%;

  @media (max-width: 992px) {
    font-size: 30px;
  }

  @media (max-width: 870px) {
    font-size: 27px;
  }

  @media (max-width: 755px) {
    font-size: 20px;
  }

  @media (max-width: 575px) {
    display: none;
  }
}

.icons {
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icons__text {
  padding-bottom: 20px;
  @include style;

  @media (max-width: 755px) {
    padding-bottom: 10px;
  }
}

.copyright {
  margin: 0 auto;
  @include style;
  padding-bottom: 5px;
  font-size: 12px;

  @media (max-width: 420px) {
    font-size: 10px;
  }
}
