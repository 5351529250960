* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0 auto;
    font-weight: 300;
    font-size: 16px;
    width: 100%;
  }
  
  
  .main{
    max-width: 1920px;
    margin: 0 auto;
}
  
  
  .container {
    padding: 0 15px;
    width: 1220px;
    max-width: 100%;
    margin: 0 auto;
  }