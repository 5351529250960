@mixin style {
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  border: none;
  font-size: 24px;

  @media (max-width: 576px) {
    font-size: 20px;
  }
}

.root {
  min-width: 340px;
  display: flex;
  flex-direction: column;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  padding-bottom: 30px;

  .root__year {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.title {
  @include style;
  background: #5fb1a2;
  margin-top: 50px;
  min-width: 100%;
  text-align: center;
  padding: 5px 0;

  @media (max-width: 576px) {
    margin-top: 30px;
  }
}

.allBooks {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card {
  max-width: 300px;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;

  @media (max-width: 400px) {
    padding: 0 5px;
  }

  @media (max-width: 992px) {
    width: 33.333%;
  }

  @media (max-width: 756px) {
    width: 50%;
  }

  .card__text {
    @include style;
    background: #fec24a;
    max-width: 250px;
    padding: 3px 0;
    text-align: center;
    width: 100%;
    margin-top: -20px;
  }
  .card__image {
    img {
      max-width: 180px;
      padding-top: 20px;
      padding-bottom: 40px;

      @media (max-width: 576px) {
        max-width: 155px;
      }

      @media (max-width: 450px) {
        max-width: 135px;
      }
    }
  }
}
