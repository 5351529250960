.root {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.date {
  color: #5fb1a2;
  text-transform: uppercase;
  font-weight: 300;
  font-family: Impact, 'Oswald', sans-serif;
  font-size: 60px;

  @media (max-width: 420px) {
    font-size: 50px;
  }
}

.month {
  margin-top: -20px;
  color: #5fb1a2;
  text-transform: uppercase;
  font-family: Impact, 'Oswald', sans-serif;
  font-size: 20px;

  @media (max-width: 420px) {
    font-size: 18px;
  }
}
