.root {
  width: 400px;
  padding: 50px;
  border: 1px solid #120101;
  margin: 50px auto;
  background-color: antiquewhite;
  border-radius: 20px;
}

.field {
  margin-bottom: 20px !important;
}

.title {
  text-align: center !important;
  font-weight: bold !important;
  margin-bottom: 30px !important;
}

.avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
