.root {
  display: flex;
  width: 100%;
  padding: 10px 5px;
  height: 100%;
}

.info {
  width: 20%;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 796px) {
    width: 15%;
    margin-left: 0;
  }
}

.content {
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(39, 97, 25, 0.15);
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 850px) {
    margin-left: 10px;
  }

  @media (max-width: 796px) {
    width: 85%;
  }

  @media (max-width: 420px) {
    margin-left: 15px;
  }

  .title {
    color: #5fb1a2;
    text-transform: uppercase;
    font-weight: 300;
    font-family: Impact, 'Oswald', sans-serif;
    font-size: 20px;

    @media (max-width: 420px) {
      font-size: 18px;
    }
  }

  .readMore {
    text-align: end;
    .readMore__link {
      text-decoration: none;
      color: #5fb1a2;
      font-size: 13px;
    }
  }
}
