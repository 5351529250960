

.littleBooks{
    background: #fbfbfb;
}

.littleBooks__main{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px 0;
    max-width: 1220px;
    margin: 0 auto;
}

.book{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
}

.littleBooks__img {
    img{
        width: 80px;
    }
}

.littleBooks__text{
    p{
        text-transform: uppercase;
        font-size: 15px;
        text-align: center;
        padding-top: 10px;
    }
}

@media (max-width: 576px) {
    .littleBooks__main{
        padding: 20px 10px;
    }

    .book{
        width: 150px;
    }
    .littleBooks__img {
        img{
            width: 50px;
        }
    }
    
    .littleBooks__text{
        p{
            font-size: 11px;
            padding: 0;
        }
    }
}