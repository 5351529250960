@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

$ubuntu-font: "Ubuntu", sans-serif;

$bg: rgb(255, 255, 255);
$bg2: #5fb1a2;
$text: rgb(15, 15, 15);
$text2: rgb(240, 240, 240);
$primary: rgb(22, 210, 247);

$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 32px;

$header-height: 150px;

$sm: 37.5em;
$md: 48em;
$lg: 64em;
$xl: 75em;

@mixin breakpoint($point) {
    @if $point == md {
        @media (min-width: $md) {
            @content;
        }
    }
}