.root {
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  background: #cfe2de;
  justify-content: space-around;
  align-items: center;
  height: 170px;
  border-radius: 10px;

  @media (max-width: 797px) {
    height: 150px;
    padding-left: 10px;
  }

  @media (max-width: 695px) {
    height: 130px;
  }
}

.form {
  padding-left: 15px;

  @media (max-width: 620px) {
    padding: 0;
    margin: 0 auto;
  }
}

.form__title {
  color: white;
  text-transform: uppercase;
  font-weight: 300;
  font-family: Impact, 'Oswald', sans-serif;
  font-size: 25px;
  padding-bottom: 10px;

  @media (max-width: 797px) {
    font-size: 22px;
  }
}

.text {
  display: flex;
  align-items: center;
}

.form__input {
  padding: 6px;
  min-width: 250px;
  border-radius: 10px;
  border: none;

  &:focus {
    outline: none;
  }

  @media (max-width: 399px) {
    padding: 5px;
  }
}

.form__button {
  margin-left: -60px;
  background: #fec24a;
  border: none;
  text-transform: uppercase;
  padding: 5px 10px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;

  &:hover {
    background: #5fb1a2;
  }

  @media (max-width: 399px) {
    margin-left: -80px;
    font-size: 13px;
  }
}
.image {
  img {
    width: 400px;

    @media (max-width: 797px) {
      width: 350px;
    }

    @media (max-width: 695px) {
      width: 270px;
    }

    @media (max-width: 620px) {
      display: none;
    }
  }
}

.answer {
  padding-top: 2px;
  color: white;
}
