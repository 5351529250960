.root {
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  align-items: center;
  padding: 0 10px;
}

.categories {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  max-width: 700px;
  width: 100%;
  padding-top: 30px;

  @media (max-width: 725px) {
    width: 100%;
  }
}

.buttons {
  padding: 5px 35px;
  border-radius: 5px;
  background: #5fb1a2;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 20px;

  &:hover {
    background: #fec24a;
  }

  &:visited {
    color: white;
  }

  @media (max-width: 725px) {
    padding: 5px 25px;
  }

  @media (max-width: 530px) {
    padding: 5px 15px;
    font-size: 17px;
  }

  @media (max-width: 530px) {
    padding: 5px 10px;
    font-size: 15px;
  }
}

.arrows {
  margin: 0 auto;
  height: 200px;
  max-width: 1200px;

  img {
    max-width: 1200px;
    width: 100%;
  }
}

.allPosts {
  border-top: 15px solid #5fb1a2;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  margin-top: 40px;
}
