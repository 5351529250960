.menu {
  background-color: #5fb1a2;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  img {
    width: 150px;
  }
}

.closed {
  display: none;
  color: #fff;
  cursor: pointer;
  justify-content: flex-start;
  width: 100%;
}

.closed .close {
  font-size: 25px;
}

.menuIcon .hamb {
  display: none;
  color: #fff;
  font-size: 50px;
  cursor: pointer;
  margin: 15px;
}

//******************
.slider ul {
  width: 100%;
  transition: 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider ul li {
  margin-block: 0.5rem;
  margin-inline: 2rem;
  list-style: none;
}

.slider ul li a {
  text-decoration: none;
  font-size: 20px;
  color: #fff;
  font-weight: bolder;
  text-transform: uppercase;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .slider {
    padding-left: 30px;
  }
}

@media (max-width: 992px) {
  .slider {
    padding-left: 60px;
  }
  .slider ul li {
    margin: 15px;
    margin-inline: 1rem;
    list-style: none;
  }
}

@media (max-width: 870px) {
  .slider {
    padding-left: 60px;
  }
  .slider ul li {
    margin: 15px;
    margin-inline: 0.8rem;
    list-style: none;
  }

  .slider ul li a {
    font-size: 18px;
  }
}

@media (max-width: 790px) {
  .logo {
    img {
      width: 130px;
      margin: 20px;
    }
  }

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .closed {
    display: flex;
    color: #fff;
    cursor: pointer;
    justify-content: flex-end;
    width: 100%;
    margin: 10px;
  }

  .closed .close {
    font-size: 40px;
    margin: 10px;
  }

  .menuIcon .hamb {
    display: block;
    color: #fff;
    font-size: 50px;
    cursor: pointer;
    font-weight: bold;
  }

  //********************
  .slider {
    position: fixed;
    min-width: 100%;
    height: 100vh;
    background-color: #5fb1a2;
    top: -300%;
    transition: 0.5s ease;
    z-index: 1000;
  }

  .slider.active {
    top: 0;
    transition: 0.5s ease;
  }

  .slider ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slider ul li {
    margin-block: 2.5rem;
    list-style: none;
  }

  .slider ul li a {
    text-decoration: none;
    font-size: 25px;
    cursor: pointer;
  }
}

.icons {
  padding-top: 30px;
}

@media (min-width: 780px) {
  .icons {
    display: none;
  }
}
