/*section footer начало*/
.wrapper {
  display: grid;
  width: 100%;
  height: 150px;
  place-items: center;
  background: linear-gradient(315deg, #ffffff, #d7e1ec);
}

.footer__info {
  text-align: center;
}

.footer__info div p a {
  text-decoration: none;
  color: black;
}

.footer {
  display: inline-flex;
}

.footer .icon {
  margin: 0 20px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.footer .icon span {
  position: relative;
  z-index: 2;
  width: 50px;
  height: 50px;
  display: block;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.footer .icon span i {
  font-size: 30px;
  line-height: 52px;
}

.footer .icon:hover span i {
  color: white;
}

.footer .icon .toolrip {
  position: absolute;
  top: 0;
  background: #fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 18px;
  border-radius: 25px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.footer .icon:hover .toolrip {
  top: -60px;
  opacity: 1;
  pointer-events: auto;
  text-align: center;
}

.footer .icon:hover span,
.footer .icon:hover .toolrip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.footer .telegram:hover span,
.footer .telegram:hover .toolrip,
.footer .telegram:hover .toolrip:before {
  background: #8abff4;
  color: white;
  font-weight: bold;
}

.footer .instagram:hover span,
.footer .instagram:hover .toolrip,
.footer .instagram:hover .toolrip:before {
  background: #de3d8d;
  color: white;
  font-weight: bold;
}

.footer .youtube:hover span,
.footer .youtube:hover .toolrip,
.footer .youtube:hover .toolrip:before {
  background: #f41515;
  color: white;
  font-weight: bold;
}

.footer .bot:hover span,
.footer .bot:hover .toolrip,
.footer .bot:hover .toolrip:before {
  background: #e1dca0;
  color: white;
  font-weight: bold;
}

.footer .patreon:hover span,
.footer .patreon:hover .toolrip,
.footer .patreon:hover .toolrip:before {
  background: #ff5900;
  color: white;
  font-weight: bold;
}

.footer .facebook:hover span,
.footer .facebook:hover .toolrip,
.footer .facebook:hover .toolrip:before {
  background: #4867aa;
  color: white;
  font-weight: bold;
}

.icon a {
  color: black;
}

a:visited {
  color: inherit;
}

/*section footer конец*/

@media (max-width: 870px) {
  .wrapper {
    height: 120px;
  }

  .footer .icon {
    margin: 5px 10px;
  }
}

@media (max-width: 755px) {
  .footer .icon span {
    width: 35px;
    height: 35px;
  }

  .footer .icon span i {
    font-size: 20px;
    line-height: 45px;
  }

  .footer .icon {
    margin: 5px 10px;
  }

  .footer__info {
    text-align: center;
  }

  .footer__info div p {
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .footer .icon span {
    width: 32px;
    height: 32px;
  }

  .footer .icon span i {
    font-size: 20px;
    line-height: 45px;
  }

  .footer .icon {
    margin: 5px 10px;
  }

  .footer__info {
    text-align: center;
  }

  .footer__info div p {
    font-size: 14px;
  }
}
