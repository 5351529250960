.monthBook {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.photo {
  .blot {
    img {
      width: 500px;
      z-index: 1000;
    }
  }
  .book {
    img {
      position: absolute;
      top: 65px;
      left: 115px;
      width: 290px;
    }
  }
}

.info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 60%;
  padding-left: 50px;
  padding-top: 70px;

  .title {
    font-family: Impact, 'Oswald', sans-serif;
    color: #5fb1a2;
    font-size: 60px;
    text-transform: uppercase;
    padding-bottom: 15px;
  }
  .text {
    font-family: 'Courier New', Courier, monospace;
    padding: 20px;
    text-transform: uppercase;
    font-size: 18px;
  }
  .button {
    button {
      background: #cfe2de;
      border: none;
      text-transform: uppercase;
      padding: 15px;
      color: white;
      border-radius: 10px;
      cursor: pointer;
      font-size: 18px;
    }
  }
}

@media (max-width: 1200px) {
  .photo {
    .blot {
      img {
        width: 450px;
      }
    }
    .book {
      img {
        top: 35px;
        left: 85px;
        width: 270px;
      }
    }
  }
  .info {
    .title {
      font-size: 50px;
      text-align: center;
    }
  }
}

@media (max-width: 992px) {
  .photo {
    .blot {
      img {
        width: 400px;
      }
    }
    .book {
      img {
        top: 35px;
        left: 85px;
        width: 250px;
      }
    }
  }
  .info {
    padding-left: 30px;
    padding-top: 50px;
    .title {
      font-size: 45px;
    }
    .text {
      font-size: 16px;
    }
  }
}

@media (max-width: 890px) {
  .monthBook {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .photo {
    text-align: center;
    .blot {
      img {
        width: 500px;
      }
    }
    .book {
      img {
        top: 45px;
        left: 290px;
        width: 290px;
      }
    }
  }
  .info {
    width: 100%;
    .title {
      font-size: 45px;
    }
    .text {
      font-size: 16px;
    }
  }
}

@media (max-width: 780px) {
  .photo {
    .blot {
      img {
        width: 450px;
      }
    }
    .book {
      img {
        top: 45px;
        left: 235px;
        width: 270px;
      }
    }
  }
}

@media (max-width: 650px) {
  .photo {
    .book {
      img {
        top: 45px;
        left: 175px;
        width: 270px;
      }
    }
  }
}

@media (max-width: 576px) {
  .photo {
    .book {
      img {
        top: 45px;
        left: 135px;
        width: 270px;
      }
    }
  }
}

@media (max-width: 470px) {
  .photo {
    .blot {
      img {
        width: 350px;
      }
    }
    .book {
      img {
        top: 0px;
        left: 100px;
        width: 230px;
      }
    }
  }
  .info {
    padding: 0;
  }
}

@media (max-width: 420px) {
  .photo {
    .blot {
      img {
        width: 300px;
      }
    }
    .book {
      img {
        top: 0px;
        left: 100px;
        width: 200px;
      }
    }
  }
}
