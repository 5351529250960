.scroll {
  opacity: 0.4;
  @media (max-width: 1250px) {
    display: none;
  }
}

.pencil {
  width: 40px;
  height: 40px;
}
