.root {
  display: flex;
  padding: 10px;
  margin: 15px;
}

.info {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;

  @media (max-width: 796px) {
    width: 15%;
    margin-left: 0;
  }
}

.content {
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(39, 97, 25, 0.15);
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 850px) {
    margin-left: 10px;
  }

  @media (max-width: 796px) {
    width: 85%;
  }

  @media (max-width: 420px) {
    margin-left: 15px;
  }

  .title {
    color: #5fb1a2;
    text-transform: uppercase;
    font-weight: 300;
    font-family: Impact, 'Oswald', sans-serif;
    font-size: 20px;

    @media (max-width: 420px) {
      font-size: 18px;
    }
  }

  .readMore {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: 10px;

    .readMore__link {
      text-decoration: none;
      color: #5fb1a2;
      font-size: 13px;
    }
    .views {
      display: flex;
      opacity: 0.4;

      @media (max-width: 725px) {
        opacity: 0;
      }
      .views__count {
        font-size: 13px;
        padding-left: 3px;
      }
    }
  }
}
