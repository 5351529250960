.root {
  display: flex;
  padding: 50px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 18px;

  @media (max-width: 699px) {
    flex-direction: column;
    align-items: center;
    font-size: 16px;
  }

  @media (max-width: 399px) {
    font-size: 14px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.content__button {
  background: #cfe2de;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  padding: 5px 25px;
  margin-right: 30px;
  cursor: pointer;
  margin-top: 30px;

  .button {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 20px;

    @media (max-width: 399px) {
      font-size: 17px;
    }
  }

  @media (max-width: 699px) {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.content__button:hover {
  background: #fec24a;
}

.content__title {
  color: #5fb1a2;
  font-size: 60px;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

  @media (max-width: 992px) {
    font-size: 50px;
  }

  @media (max-width: 820px) {
    font-size: 40px;
  }

  @media (max-width: 399px) {
    font-size: 30px;
  }
}

.image {
  padding-left: 150px;

  @media (max-width: 1200px) {
    padding-left: 50px;
  }

  @media (max-width: 992px) {
    padding-left: 30px;
  }

  @media (max-width: 820px) {
    padding-left: 0;
  }
  img {
    max-width: 400px;
    @media (max-width: 992px) {
      max-width: 350px;
    }
    @media (max-width: 820px) {
      max-width: 300px;
    }

    @media (max-width: 399px) {
      max-width: 250px;
    }
  }
}
