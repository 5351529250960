.infoBlock {
    display: flex;
    padding: 20px 0;
}

.left__title{
    padding-bottom: 20px; 
}

.left__title__first {
    color: #5fb1a2;
    text-transform: uppercase;
    font-weight: 300;
    font-family: Impact, 'Oswald', sans-serif;
    font-size: 40px;
}

.left__title__second{
    color: #fec24a;
    text-transform: uppercase;
    font-weight: 300;
    font-family: Impact, 'Oswald', sans-serif;
    font-size: 25px;
}

.right__infoBlock {
    p{
        padding-bottom: 20px; 
    }
}




@media (max-width: 1220px) {
    .right__infoBlock, .left__infoBlock {
        padding-left: 10px;
        padding-right: 10px;
    }
} 


@media (max-width: 780px) {
    .infoBlock {
        flex-direction: column;
    }
    .left__infoBlock{
        padding-bottom: 20px;
    }

    .left__title{
        text-align: center;
    }
}


@media (max-width: 576px) {
    .left__title__first {
        font-size: 30px;
        padding-bottom: 5px;
    }
    
    .left__title__second{
        font-size: 19px;
    }
}

