.root {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 15px auto;
  padding: 10px;
}

.post {
  width: 100%;
  padding: 10px;

  border-radius: 10px;
  box-shadow: 0 0 10px rgba(39, 97, 25, 0.15);

  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 25px;
  padding-bottom: 20px;
}

.readMore {
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  margin: 0 auto;
  font-size: 20px;
  padding: 30px 0;
}

.other_posts {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;

  @media (max-width: 992px) {
    flex-direction: column;
    width: 100%;
  }
}

.title__footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
}

.views {
  display: flex;
  width: 100%;

  opacity: 0.3;
  align-items: center;

  .views__count {
    padding-left: 3px;
  }
}

.button {
  width: 300px;
  background: #5fb1a2;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  padding: 5px 25px;
  margin-right: 30px;
  cursor: pointer;

  @media (max-width: 380px) {
    margin-right: 10px;
  }

  .button__link {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 20px;

    @media (max-width: 480px) {
      font-size: 15px;
    }
  }
}

.button:hover {
  background: #fec24a;
}
